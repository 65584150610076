<template>
  <section
    class="m-short-text"
    ref="lazy"
    :class="isContentLarge ? 'm-short-text--long' : 'm-short-text--short'"
    v-if="displayedText"
  >
    <div
      class="m-short-text__content"
      :class="{ 'm-short-text__content--collapsed': isContentLarge && !isShowMore }"
      ref="content"
      :style="computedStyle"
      v-html="displayedText"
    />

    <SfButton
      class="m-short-text__link sf-link sf-link--primary sf-button--text"
      data-transaction-name="SB - Show More"
      @click="showMore"
      v-if="isContentLarge"
    >
      {{ showMoreText }}
    </SfButton>
  </section>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { safeHtml } from 'theme/helpers/safe-html';

const MAX_HEIGHT_SHORT_TEXT = 70; // px

export default {
  name: 'MShortText',
  components: { SfButton },
  data () {
    return {
      isContentLarge: false,
      isShowMore: false,
      seoTextHeight: MAX_HEIGHT_SHORT_TEXT
    }
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    displayedText () {
      return safeHtml(this.text)
    },
    showMoreText () {
      return this.$t(this.isShowMore ? 'Hide' : 'Show more');
    },
    computedStyle () {
      return !this.isShowMore ? { maxHeight: MAX_HEIGHT_SHORT_TEXT + 'px' } : {};
    }
  },
  methods: {
    getContentElement () {
      if (!this.$refs.lazy) return
      const el = this.$refs.lazy

      if (!el || !el.querySelector) return

      return el.querySelector('.m-short-text__content')
    },
    calculateHeight (content) {
      this.isContentLarge = content.offsetHeight >= MAX_HEIGHT_SHORT_TEXT;
      this.isShowMore = false;
    },
    showMore () {
      this.isShowMore = !this.isShowMore;
    }
  },
  mounted () {
    const content = this.getContentElement()

    if (!content) return

    this.calculateHeight(content);
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-short-text {
  font-size: var(--font-size-14);
  line-height: 160%;

  &__content {
    overflow: hidden;
    position: relative;

    &--collapsed {
      &::after {
        background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        bottom: 0;
        content: '';
        left: 0;
        height: var(--spacer-20);
        position: absolute;
        right: 0;
      }
    }
  }

  &__link {
    margin-top: var(--spacer-10);
    --button-font-size: var(--font-sm);
    --button-font-weight: var(--font-normal);
    --button-background: transparent;
    --button-text-decoration: none;
  }
}
</style>
